<template>
  <div style="text-align: center;font-size: 0.4rem;margin-top: 0.5rem;">加载中...</div>
</template>

<script>
import { request } from "@/api/service";
import { authorize } from "@/utils/weixin";
function getUrlParam(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const url = window.location.href.split("#")[0];
  const search = url.split("?")[1];
  if (search) {
    let r = search.substr(0).match(reg);
    if (r !== null) return unescape(r[2]);
    return null;
  } else {
    return null;
  }
}
export default {
  data() {
    return {};
  },
  async created() {
    const { orderId } = this.$route.query;
    if (!orderId) {
      return;
    }
    const res = await request({
      url: `/afis-carservice/order/payOrderInfo/${orderId}`,
      method: "GET"
    });
    if (res && res.orderStatus === "7" && res.payStatus === "1") {
      const state = getUrlParam("state");
      const code = getUrlParam("code");
      if (!state) {
        authorize("#" + this.$route.fullPath);
      }
      this.$router.replace({
        path: "/product/otherPay",
        query: {
          orderId,
          state,
          code
        }
      });
    } else {
      this.$router.replace({
        path: "/product/otherPay/info",
        query: {
          orderId
        }
      });
    }
  },
  methods: {}
};
</script>
